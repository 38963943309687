import React from 'react'
// import Button from '../../../../Button/Button'
import style from './Sclad.module.css'
// import Title from "../../Title";
import { useEffect, useState, useContext } from "react";
import { QtdContext } from "../../../../../../../context/qtdContext";
import $api from "../../../../../../../http/index.ts";
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import 'react-tabs/style/react-tabs.css';
import { FaEdit, FaRemoveFormat } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import ModalEditProduct from '../../../Modal/ModalEditProduct';
import { confirmAlert } from 'react-confirm-alert'; // Import
import '../../../NewOrders/react-confirm-alert.css'; // Import css
import { FcInfo } from "react-icons/fc";
import { BACKURL } from '../../../../../../../conf.js'
import styled from "styled-components";
import Loading from 'react-fullscreen-loading';

const OurProds = ({ kategory }) => {
    const { shops, setShops, productDots, setproductDots, optUsers, seteditprod_id, sclad, setSclad, modalEditProduct, setModalEditProduct } = useContext(QtdContext);
    // const { modal, setModal } = useState(false);
    const [loading, setLoading] = useState(false);

    const lacom = sclad.filter((post) => (post.kategory.includes(kategory))).sort((a, b) => a.name.localeCompare(b.name));
    // useEffect(() => {
    //     window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    // }, [])
    const TCheckBox = styled.input.attrs({
        type: "checkbox"
    })`
        display: none;
      `;

    const TCheckBoxWrapper = styled.div`
        background: ${p => (p.checked ? "#3f81ff" : "#ececec")};
        border-radius: 4rem;
        height: 32px;
        width: 68px;
        position: relative;
        transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
        cursor: pointer;
      `;

    const TCheckBoxToggle = styled.div`
        background: #fff;
        border-radius: 4rem;
        height: 26px;
        width: 26px;
        top: 3px;
        left: ${p => (p.checked ? "38px" : "4px")};
        position: absolute;
        transition: cubic-bezier(0.56, 0, 0.38, 1) 0.3s;
      `;

    const ToggleCheckbox = props => {
        return (
            <TCheckBoxWrapper checked={props.checked} onClick={props.onChange}>
                <TCheckBoxToggle checked={props.checked} />
                <TCheckBox {...props} />
            </TCheckBoxWrapper>
        );
    };
    const toggle = async (id) => {
        try {
            setLoading(true)
            // console.log(id)
            const deleteProd = await $api.get(`${BACKURL}/api/utbteam/products/showed/${id}`)
            setSclad(sclad.map(pr => {
                if (pr.id == id) {
                    return deleteProd.data
                } else {
                    return pr
                }
            }))
            //   setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    };
    const toggleDonation = async (id) => {
        try {
            setLoading(true)
            // console.log(id)
            const deleteProd = await $api.get(`${BACKURL}/api/utbteam/products/donation/${id}`)
            setSclad(sclad.map(pr => {
                if (pr.id == id) {
                    return deleteProd.data
                } else {
                    return pr
                }
            }))
            //   setSclad(sclad.map(item => item.id == id ? deleteProd.data : item))
            setLoading(false)
        } catch (e) {
            setLoading(false)

            console.log(e)
        }
    };
    const [thisChange, setThisChange] = useState(null)

    const edit = (id) => {
        seteditprod_id(id)
        setModalEditProduct(true)

    }
    const deleteProd = async (id) => {
        const DeletedId = await $api.get(`${BACKURL}/api/utbteam/deleteproduct/${id}`)
        const newprod = sclad.filter((post) => post.id != id)
        setSclad(newprod)
        alert('Товар удален! Эта функция будет ограничена!')
    }
    const submit = async (func, data) => {
        confirmAlert({
            title: 'Подтвердите, чтобы продолжить!',
            message: 'Вы уверены, в том, что хотите удалить ордер?',
            buttons: [
                {
                    label: 'Да!',
                    onClick: async () => await func(data)
                },
                {
                    label: 'Нет!',
                    //   onClick: () => alert('Click No')
                }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
        });
    };

    return (<>
        <div style={{ overflowX: 'auto', width: '100%' }}>

            <br />
            <table className={style.table}>
                <thead className={style.tableth}>

                    <tr>
                        <td className={style.tabletd}>Код</td>
                        <td className={style.tabletd}>Наименоване</td>
                        <td className={style.tabletd}>Категория</td>
                        {/* {admin.role == 'GOD' && (
<>
                            <td className={style.tabletd}>Описание</td>
                            <td className={style.tabletd}>Рекомендации</td>
                        </>)} */}

                        {shops.length > 0 && (shops.map(pr => <td className={style.tabletd}>{pr.name}</td>))}
                        {/* <td className={style.tabletd}>Брак</td> */}

                        <td className={style.tabletd}>Розница</td>
                        <td className={style.tabletd}>Показывается</td>
                        <td className={style.tabletd}>%</td>
                        <td className={style.tabletd}>Пожертвование</td>
                        <td className={style.tabletd}>Действия</td>
                        {/* <td className={style.tabletd}>Опт без НДС</td> */}
                        {optUsers.length > 0 &&
                            (<>
                                {optUsers.map(ou => (
                                    <td className={style.tabletd}>{ou.name}</td>

                                ))}
                            </>)
                        }
                        {/* <td className={style.tabletd}>Опт с НДС</td> */}
                    </tr>
                </thead>
                <tbody>
                    {lacom.map(
                        (prod, index) => <>
                            <tr>
                                <td className={style.tabletd}>

                                    <div className={style.cell}>

                                        {prod.shcode}
                                    </div>


                                </td>

                                <td className={style.tabletd}>

                                    <div className={style.cell}>
                                        {prod.name}
                                    </div>


                                </td>
                                <td className={style.tabletd}>  {prod.kategory}</td>

                                {shops.length > 0 && (shops.map(pr => {

                                    const scladqty = productDots.find(pd => pd.prod_sh == prod.shcode && pd.prod_id == prod.id && pr.id == pd.shop_id)
                                    // console.log(productDots, scladqty)
                                    return <td className={style.tabletd}>

                                        <b>   {scladqty ? scladqty.scladqty : 0}{prod.mnt}</b>



                                    </td>
                                }))}
                                {/* <td className={style.tabletd} style={{maxWidth:'100px'}}>


                                  <b> {prod.brakqty} {prod.mnt}</b> 

                                </td> */}
                                <td className={style.tabletd}>
                                    <div className={style.cell}>

                                        {prod.price}RUB
                                    </div>


                                </td>
                                <td className={style.tabletd}>
                                    <div className={style.cell}>
                                        <ToggleCheckbox checked={prod.showed} onChange={() => toggle(prod.id)} />

                                    </div>


                                </td>
                                <td className={style.tabletd}>
                                    <div className={style.cell}>

                                        {prod.donSale}
                                    </div>


                                </td>
                                <td className={style.tabletd}>
                                    <div className={style.cell}>
                                        <ToggleCheckbox checked={prod.donation} onChange={() => toggleDonation(prod.id)} />

                                    </div>


                                </td>



                                <td className={style.tabletd}>
                                    <div className={style.cell}>
                                        <AiFillDelete onClick={() => { submit(deleteProd, prod.id) }} className={style.icon} />
                                        <FaEdit className={style.icon} onClick={() => edit(prod.id)} />

                                    </div>


                                </td>
                                {/* <td className={style.tabletd}>
                                    <div className={style.cell}><>
                                        {admin.role == 'GOD' && (
                                            <>
                                                <FaEdit onClick={() => { edit(prod, false, false, true, false, false, false, false, false, '') }} className={style.icon} />

                                            </>)}
                                        {prod.optprice}RUB
                                    </> </div>


                                </td> */}
                                {/* {optUsers.length > 0 && prod.vipprice != undefined &&
                                    (<>
                                        {optUsers.map(ou => (<>
                                            {console.log((prod.vipprice.find(vp => vp.optuserid == ou.id)))}

                                            {(prod.vipprice.find(vp => vp.optuserid == ou.id)) && (
                                                <td className={style.tabletd}>

                                                    <div className={style.cell}><>
                                                        <FaEdit onClick={() => { edit(prod, false, false, false, false, false, false, false, true, ou.id) }} className={style.icon} />
                                                        {(prod.vipprice.find(vp => vp.optuserid == ou.id)).vipprice}RUB

                                                    </> </div>

                                                </td>

                                            )}
                                          

                                        </>))}
                                    </>)
                                } */}
                                {/* <td className={style.tabletd}>0 RUB</td> */}



                            </tr>

                        </>
                    )}
                </tbody>
            </table>
            {/* {modalEditProduct && (
                <ModalEditProduct
                    id={thisChange}
                    // setThisChange={setThisChange}
                // prod = {prod}
                />

            )} */}
            {loading && (
                <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
            )}
        </div>
    </>
    )
}
export default OurProds
