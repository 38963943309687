import React, { useContext, useEffect, useState } from "react";
import style from "./Modal.module.css";
// import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../../../../Button/Button";
import { QtdContext } from "../../../../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import Title from "../../../../Title";
import $api from '../../../../../http/index.ts'
import { BACKURL } from '../../../../../conf.js'
import Loading from 'react-fullscreen-loading';
import ButtonsModalDonatw from "../../../../Modal/ButtonsModal/ButtonsModalDonatw.js";
const ModalDonatePriuts = ({ id, maxumum }) => {
  const [loading, seloading] = useState(false)

  const { setServerProd, donatePriutModal, setProdutosSelecionadosDonations, setProdutosSelecionados, produtosSelecionadosDonations, setDonatePriutModal, priuts, setPriuts, setModalInfoSpec, setModalAddSpec, spec, modalAddSpec, setSpec, setModalInfo, modalAddProduct, admin, servOrders, setServOrders, serverProd, sclad, setSclad, infomodal, setModalAddProduct, setModalEditProduct } = useContext(QtdContext);
  const thisProd = produtosSelecionadosDonations.find(pr => pr.id == id) ? produtosSelecionadosDonations.find(pr => pr.id == id) : serverProd.find(pr => pr.id == id)
  console.log('produtosSelecionados.find(pr => pr.id == id)', produtosSelecionadosDonations.find(pr => pr.id == id))
  console.log('serverProd.find(pr => pr.id == id)', serverProd.find(pr => pr.id == id))
  console.log(thisProd)
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container12462" && setDonatePriutModal(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };
  const Donations = thisProd.donations


  // infomodal.data
  const [err, setErr] = useState({
    name: false,
    qty: false
  })


  const removeItemDon = (itemId, priut_id) => {
    // console.log(itemId, priut_id)
    const prodRemove = produtosSelecionadosDonations.find(produtoid => produtoid.id == itemId)
    if (prodRemove) {
      // console.log('prodRemove', prodRemove)

      const don = prodRemove.donations.filter(d => d.id != priut_id)
      // console.log('don', don)

      if (don.length == 0) {
        const newDona = produtosSelecionadosDonations.filter(pr => pr.id != prodRemove.id)
        // console.log('newDona', newDona)



        // localStorage.setItem("petNewDonations", JSON.stringify(newDona))

        localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(newDona))
        setProdutosSelecionadosDonations(newDona)
      } else {
        const newDone = produtosSelecionadosDonations.map(pr => {
          if (pr.id == prodRemove.id) {
            return { ...pr, donations: don }
          } else return pr
        })
        // console.log('newDone', newDone)


        // localStorage.setItem("petNewDonations", JSON.stringify(newDone));
        localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(newDone));
        setProdutosSelecionadosDonations(newDone)

      }
    }
    // if (prodRemove.length == 0) {
    //   setPrecoTotal(0)
    // }
    // precoFinal = prodRemove.map(({ price, quantidade }) => Number(price) * quantidade)
    // setProdutosSelecionados(prodRemove)

  }


  return (
    <>
      {donatePriutModal && (
        <div onClick={closeModal} id="container12462" className={style.modal_container} style={{ padding: '20px', paddingTop: '100px' }}>

          <div className={style.modal} style={{ width: 'auto', padding: '20px', justifyContent: 'center', textAlign: 'center' }}>
            <div>
              <Title text={thisProd.name}></Title>
            </div>
            <div >
              <p>Данный товар вы можете купить со скидкой {thisProd.donSale}%. Мы накапливаем корзины для приютов и раз в месяц отвозим их.</p>
            </div>
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center' }}>
              {priuts.length > 0 ? (
                priuts.map((str, index) => {
                  const thisDon = Donations.find(pr => pr.id == str.id)
                  // console.log(thisDon)
                  return (<div style={{ margin:'10px',padding:'10px',borderBottom:'1px black solid',width: '100%', paddingTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', textAlign: 'center' }} key={str.id}>
                    <div style={{ textAlign: 'center' }}>
                      <b>{str.name}</b>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                      <ButtonsModalDonatw priut_id={str.id} id={id} max={maxumum} />
                      <p className={style.produto_preco}> {(thisProd.price * (1 - thisProd.donSale / 100)).toFixed(2)}р (-{thisProd.donSale}%)</p>
                      <p style={{ color: 'black', width: '90px' }} className={style.produto_preco}> {(Number((thisProd.price * (1 - thisProd.donSale / 100)).toFixed(2)) * (thisDon ? thisDon.qty : 0)).toFixed(2)}р</p>

                      <div className={style.iconRemove}>
                        <BiTrash onClick={() => removeItemDon(id, str.id)} />
                      </div>
                    </div>



                  </div>)
                }
                )
              ) : (
                <p>Массив пуст</p>
              )}
            </div>
          </div>
        </div>

        // </div>
      )}
      {loading && (
        <Loading loading background="rgba(255, 165, 0,0.75)" loaderColor="#3498db"></Loading>
      )}
    </>
  );
};

export default ModalDonatePriuts;
