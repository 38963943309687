import React, { useEffect, useContext, useState } from "react";
import {useNavigate, NavLink, useLocation } from "react-router-dom";
import Cart from "../Cart/Cart";
import Button from '../Button/Button'
import { QtdContext } from "../../context/qtdContext";
import $api from "../../http/index.ts";

import style from "./LinksMenu.module.css";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Cards from "../Cards/Cards.js";
import CardsForDog from "../Cards/CardsForDog.js";
import CardsForCat from "../Cards/CardsForCat.js";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const LinksMenuMobile = (closeModal) => {
  const location = useLocation()
  const navigate = useNavigate();
  // const { auth, setAuth } = useContext(QtdContext);
  const { menu, setMenu } = useContext(QtdContext);

  let test = true
  if ((location.pathname == "/createorder" || location.pathname == "/success")) {
    test = false
  }
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={style.menu_container}>
      <div style={{ textShadow: '#20b6e8 2px 2px 0px;', marginTop: '80px', color: 'black', width: '100%' }}>
        <Accordion expanded={expanded === 'panel1'} onClick={()=>{navigate('/');   setMenu(false)}} style={{ display: 'block', height: '49px', }} onChange={handleChange('panel1')}>
          <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>   <b> <NavLink style={{ fontSize: '14px', color: 'black' }} className='link_item' exact="true" to="/">
              Главная

            </NavLink></b>
            </Typography>
          </MuiAccordionSummary>

        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography className={style.ulmobitem}>Наша продукция</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ background: "url(https://api.kotopes39.ru/static/newback.png)" }}>
            <Cards />
            {/* <Typography>
              <b>
                <NavLink style={{ fontSize: '14px' }} className={style.links} to="cuns">
                  Консервы
                </NavLink>
              </b>
            </Typography>
            <Typography>
              <b>
                <NavLink style={{ fontSize: '14px' }} className='link_item' to="lacomstva">
                  Лакомства

                </NavLink>
              </b>
            </Typography>
            <Typography>
              <b>
                <NavLink style={{ fontSize: '14px' }} className='link_item' to="racions">
                  Рационы


                </NavLink>
              </b>
            </Typography>
            <Typography>
              <b>
                <NavLink style={{ fontSize: '14px' }} className='link_item' to="farsh">
                  Фаршики


                </NavLink>
              </b>
            </Typography>
            <Typography>
              <b>
                <NavLink style={{ fontSize: '14px' }} className='link_item' to="zamorozka">
                  Заморозка


                </NavLink>
              </b>
            </Typography> */}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Для Собак</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ background: "url(https://api.kotopes39.ru/static/newback.png)" }}>
            <CardsForDog />

          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Для Кошек</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ background: "url(https://api.kotopes39.ru/static/newback.png)" }}>
            <CardsForCat />

          </AccordionDetails>
        </Accordion>
        <Accordion  onClick={()=>{navigate('/priuts');   setMenu(false)}}  expanded={expanded === 'panel1'} style={{ display: 'block', height: '49px', }} onChange={handleChange('panel1')}>
          <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>   <b> <NavLink style={{ fontSize: '14px', color: 'black' }} className='link_item' exact="true" to="/contacts">
              Приюты

            </NavLink></b>
            </Typography>
          </MuiAccordionSummary>

        </Accordion>
        <Accordion  onClick={()=>{navigate('/contacts');   setMenu(false)}}  expanded={expanded === 'panel1'} style={{ display: 'block', height: '49px', }} onChange={handleChange('panel1')}>
          <MuiAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>   <b> <NavLink style={{ fontSize: '14px', color: 'black' }} className='link_item' exact="true" to="/contacts">
              Контакты

            </NavLink></b>
            </Typography>
          </MuiAccordionSummary>

        </Accordion>
      </div>
      {/* <ul className={style.ulmobile} style={{ textShadow: '#20b6e8 2px 2px 0px;' }}>
        <div className={style.ulmobitem}>
        <b> <NavLink style={{ fontSize: '14px' }}  className='link_item' exact="true" to="/">
            Главная

          </NavLink></b>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className={style.links} to="cuns">
            Консервы

          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="lacomstva">
            Лакомства

          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="racions">
            Рационы


          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="farsh">
            Фаршики


          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="zamorozka">
            Заморозка


          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="suhkorm">
            Сухие Корма


          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="care">
            Уход


          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="amunition">
            Амуниция


          </NavLink>
        </div>

        <div className={style.ulmobitem}>
          <NavLink style={{ fontSize: '14px' }}  className='link_item' to="contacts">
            Контакты
          </NavLink>
        </div>


     
      </ul> */}
      {test && (
        <div className={style.cart}>
          <Cart />
        </div>
      )}
    </div>
  );
};

export default LinksMenuMobile;