import React, { useContext, useEffect, useState } from "react";
import { IoMdCart } from "react-icons/io";
import { QtdContext } from "../../context/qtdContext";
import ModalCart from "../Modal/ModalCart";
import style from "./Cart.module.css";

const Cart = () => {
  const [itenscart, setItenscart] = useState(0);
  const { produtosSelecionadosDonations, modalCart, setModalCart, produtosSelecionados } = useContext(QtdContext);

  useEffect(() => {
    let don = 0
    for (let i = 0; i < produtosSelecionadosDonations.length; i++) {
      don=don+produtosSelecionadosDonations[i].donations.length
    }
    setItenscart(produtosSelecionados.length+don)
  }, [produtosSelecionados,produtosSelecionadosDonations])

  const openModalCart = () => {
    setModalCart(!modalCart);
  };
  return (
    <div className={style.cart_container}>
      {itenscart > 0 && <span className={style.cart_total}>{itenscart}</span>}
      <IoMdCart onClick={openModalCart} className={style.cart} />
      <ModalCart setModalCart={openModalCart} modalCart={modalCart} />
    </div>
  );
};

export default Cart;
