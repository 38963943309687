import React, { useContext, useEffect, useState } from "react";
import style from "./ModalCart.module.css";
import ButtonsModal from "./ButtonsModal/ButtonsModal";
import Button from "../Button/Button";
import { QtdContext } from "../../context/qtdContext";
import { BiTrash } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom';
import ButtonsModalVes from "./ButtonsModal/ButtonsModalVes";
import ButtonsModalDonatw from "./ButtonsModal/ButtonsModalDonatw";
const ModalCart = ({ modalCart }) => {
  const navigate = useNavigate();
  const produtosCart = JSON.parse(localStorage.getItem("pet"));
  const produtosCartD = JSON.parse(localStorage.getItem("produtosSelecionadosDonations"));
  const { quantidadeModalBtnDonations, produtosSelecionadosDonations, shops, setProdutosSelecionadosDonations, productDots, setModalCart, productGroups, opt, quantidadeModalBtn, produtosSelecionados, setProdutosSelecionados, priuts } = useContext(QtdContext);
  const shops2 = shops.filter(pr => pr.showed)


  const [precoTotal, setPrecoTotal] = useState(0);
  let precoFinal = produtosSelecionados.map(({ price, quantidade }) => Number(price) * quantidade)
  let precoFinalDonations = produtosSelecionadosDonations.map(({ price, donSale, donations }) => {
    const totalQty = donations.reduce((sum, item) => sum + item.qty, 0);
    return (Number(price) * (1 - Number(donSale) / 100)) * totalQty
  })


  if (opt.auth) {
    precoFinal = produtosSelecionados.map(({ optprice, vipprice, quantidade }) => {
      // console.log(vipprice)
      if (vipprice) {
        const vp = vipprice.find(vp => vp.optuserid == opt.user.id)
        if (vp.vipprice > 0) {
          return Number(vp.vipprice) * quantidade
        } else {
          return Number(optprice) * quantidade
        }
      }


    })
  }
  // console.log('produtosSelecionadosDonations=', produtosSelecionadosDonations)

  // if(opt.auth){
  //   // if(vipprice.map((vp) => vp.optuserid == opt.user.id) && vipprice.map((vp) => vp.optuserid == opt.user.id) == 0){
  //     precoFinal = produtosSelecionados.map(({ optprice, vipprice,quantidade }) => {
  //   if(vipprice.map((vp) => vp.optuserid == opt.user.id) && vipprice.map((vp) => vp.optuserid == opt.user.id) == 0){

  //   }

  //       Number(optprice) * quantidade}
  //       )
  const removeItem = async (itemId) => {
    const prodRemove = produtosSelecionados.filter(produtoid => produtoid.id !== itemId)
    if (prodRemove.length == 0) {
      setPrecoTotal(0)
    }
    // precoFinal = prodRemove.map(({ price, quantidade }) => Number(price) * quantidade)
    setProdutosSelecionados(prodRemove)
    localStorage.setItem("produtosSelecionados", JSON.stringify(prodRemove));

  }
  const removeItemDon = async (itemId, priut_id) => {
    // console.log(itemId, priut_id)
    const prodRemove = produtosSelecionadosDonations.find(produtoid => produtoid.id == itemId)
    if (prodRemove) {
      // console.log('prodRemove', prodRemove)

      const don = prodRemove.donations.filter(d => d.id != priut_id)
      // console.log('don', don)

      if (don.length == 0) {
        const newDona = produtosSelecionadosDonations.filter(pr => pr.id != prodRemove.id)
        // console.log('newDona', newDona)


        // localStorage.setItem("petNewDonations", JSON.stringify(newDona))

        localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(newDona))
        setProdutosSelecionadosDonations(newDona)
      } else {
        const newDone = produtosSelecionadosDonations.map(pr => {
          if (pr.id == prodRemove.id) {
            return { ...pr, donations: don }
          } else return pr
        })
        // console.log('newDone', newDone)


        // localStorage.setItem("petNewDonations", JSON.stringify(newDone));
        localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(newDone));
        setProdutosSelecionadosDonations(newDone)

      }
    }
    // if (prodRemove.length == 0) {
    //   setPrecoTotal(0)
    // }
    // precoFinal = prodRemove.map(({ price, quantidade }) => Number(price) * quantidade)
    // setProdutosSelecionados(prodRemove)

  }
  const StarMakeOrder = () => {
    const busket = produtosSelecionados
    const busketDonations = produtosSelecionadosDonations
    if (busket.length == 0 && busketDonations.length == 0) {
      // console.log('busket empty')
      return 'Корзина пуста!'
    }
    // console.log('Заказ')
    // localStorage.setItem("newOrder", JSON.stringify({
    //   order: busket,
    //   email: '',
    //   phone: '',
    // }));
    // setNewOrder({
    //   order: busket,
    //   email: '',
    //   phone: '',
    // })
    // console.log(busket)
    setModalCart(false)
    if (opt.auth) {
      navigate('/createoptorder');

    } else {
      navigate('/createorder');

    }

  }
  useEffect(() => {

    // if (produtosSelecionados.length||produtosSelecionadosDonations.length) {

    const total = precoFinal.reduce((a, b) => a + b, 0)
    const totald = precoFinalDonations.reduce((a, b) => a + b, 0)

    // console.log(totald,total,produtosSelecionados.length,produtosSelecionadosDonations.length)
    setPrecoTotal(totald + total)
    // }
  }, [produtosCart.length, produtosCartD, precoFinal, precoFinalDonations, quantidadeModalBtn, quantidadeModalBtnDonations, produtosSelecionadosDonations, produtosSelecionadosDonations.length, produtosSelecionados]);
  const closeModal = ({ target }) => {
    // console.log(target)
    target.id === "container1" && setModalCart(false);
    // target.innerText === "ОТМЕНИТЬ" && setModal(false);
  };


  return (
    <>
      {modalCart && (
        <div onClick={closeModal} id="container1" className={style.modal_container}>
          <div className={style.container_modalcart}>
            <div className={style.container_produtos}>
              {produtosSelecionados.length > 0 && (

                produtosSelecionados.map((produtos) => {
                  let maximum2 = 0
                  for (let i = 0; i < shops2.length; i++) {
                    const scladqty = productDots.find(pd => shops2[i].showed && shops2[i].showed && pd.prod_sh == produtos.shcode && pd.prod_id == produtos.id && shops2[i].id == pd.shop_id)
                    if (scladqty) {
                      maximum2 = maximum2 + scladqty.scladqty
                      // console.log(shops2[i], scladqty)
                    }
                  }

                  const prod50 = productGroups.find(pr => pr.sh50 == produtos.shcode)
                  const prod100 = productGroups.find(pr => pr.sh100 == produtos.shcode)
                  const prod1000 = productGroups.find(pr => pr.sh1000 == produtos.shcode)
                  return (
                    <div key={produtos.name} className={style.container_modalinfos} style={{ paddingBottom: '30px' }}>
                      {produtos.photo && (
                        <div className={style.container_image}>
                          <img src={prod50 ?
                            prod50.photo[0] : prod100 ?
                              prod100.photo[0] : prod1000 ?
                                prod1000.photo[0] :
                                produtos.photo[0]} style={{ 'border-radius': '30px' }} />
                        </div>
                      )}

                      <div className={style.container_infos}>
                        <p className={style.produto_nome}>{produtos.name}</p>
                        {/* <p className={style.produto_desc}>{produtos.text}</p> */}
                        {!opt.auth && <p className={style.produto_preco}>{produtos.price}р</p>}
                        {opt.auth && (produtos.vipprice.find(vp => vp.optuserid == opt.user.id)).vipprice > 0 && (
                          <p className={style.produto_preco}>{(produtos.vipprice.find(vp => vp.optuserid == opt.user.id)).vipprice}р</p>
                        )
                        }
                        {opt.auth && (produtos.vipprice.find(vp => vp.optuserid == opt.user.id)).vipprice == 0 && (
                          <p className={style.produto_preco}>{produtos.optprice}р</p>
                        )
                        }
                      </div>
                      <div className={style.ButtonsModal}>
                        {produtos.kategory == 'сушенная продукция для собак' && produtos.mnt == 'кг' && (
                          <ButtonsModalVes cartProdutoId={produtos.id} max={maximum2} />
                        )}
                        {produtos.kategory == 'сушенная продукция для собак' && produtos.mnt == 'шт' && (
                          <ButtonsModal cartProdutoId={produtos.id} max={maximum2} />
                        )}
                        {produtos.kategory !== 'сушенная продукция для собак' && (
                          <ButtonsModal cartProdutoId={produtos.id} max={maximum2} />
                        )}


                      </div>
                      <div className={style.iconRemove}>
                        <BiTrash onClick={() => removeItem(produtos.id)} />
                      </div>
                    </div>
                  )
                })
              )}
              {produtosSelecionadosDonations.length > 0 && (
                produtosSelecionadosDonations.map((produtos) => {
                  let maximum2 = 0
                  for (let i = 0; i < shops2.length; i++) {
                    const scladqty = productDots.find(pd => shops2[i].showed && shops2[i].showed && pd.prod_sh == produtos.shcode && pd.prod_id == produtos.id && shops2[i].id == pd.shop_id)
                    if (scladqty) {
                      maximum2 = maximum2 + scladqty.scladqty
                      // console.log(shops2[i], scladqty)
                    }
                  }

                  const prod50 = productGroups.find(pr => pr.sh50 == produtos.shcode)
                  const prod100 = productGroups.find(pr => pr.sh100 == produtos.shcode)
                  const prod1000 = productGroups.find(pr => pr.sh1000 == produtos.shcode)
                  const don = produtos.donations

                  return <>
                    {/* <h3 style={{textAlign:'center'}}>Пожертвования приютам</h3> */}

                    {don.map(donat => {
                      const priut = priuts.find(r => r.id == donat.id)
                      return (<>

                        <div key={produtos.name} className={style.container_modalinfos} style={{ display: 'flex', flexDirection: 'column', paddingTop: '50px' }}>
                          <p className={style.produto_preco}>Пожертвование для приюта {priut.shortname}</p>

                          <div key={produtos.name} className={style.container_modalinfos} style={{ display: 'flex', flexDirection: 'row', borderBottom: 'none', paddingTop: '10px', paddingBottom: '10px' }}>

                            {produtos.photo && (
                              <div className={style.container_image}>
                                <img src={prod50 ?
                                  prod50.photo[0] : prod100 ?
                                    prod100.photo[0] : prod1000 ?
                                      prod1000.photo[0] :
                                      produtos.photo[0]} style={{ 'border-radius': '30px' }} />
                              </div>
                            )}

                            <div className={style.container_infos}>
                              <p className={style.produto_nome}>{produtos.name}</p>
                              {/* <p className={style.produto_desc}>{produtos.text}</p> */}
                              {!opt.auth && <p className={style.produto_preco}> {(produtos.price * (1 - produtos.donSale / 100)).toFixed(2)}р (-{produtos.donSale}%)</p>}

                            </div>
                            <div className={style.ButtonsModal}>

                              {produtos.kategory !== 'сушенная продукция для собак' && (
                                <ButtonsModalDonatw priut_id={donat.id} id={produtos.id} max={maximum2} />
                              )}


                            </div>
                            <div className={style.iconRemove}>
                              <BiTrash onClick={() => removeItemDon(produtos.id, donat.id)} />
                            </div>

                          </div>

                        </div>

                      </>
                      )
                    })}
                  </>


                })

              )}

              {produtosSelecionados.length == 0 &&produtosSelecionadosDonations.length==0&& (
                <div className={style.container_modalinfos}>


                  <p >Корзина Пуста!</p>


                </div>

              )}
            </div>

            <div className={style.total_container}>
              <div className={style.total}>
                <p className={style.produto_preco}>Итого:</p>
                <p>{precoTotal.toLocaleString('pt-br', { style: 'currency', currency: 'RUB' })}</p>
              </div>
              <Button
                text="Оформить заказ"
                background="var(--primary-color)"
                color="white"
                width="100%"
                onClick={() => StarMakeOrder()}
              />
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default ModalCart;
