import React from 'react'
import Button from '../../Button/Button'
import style from './Contato.module.css'
import Title from "../../Title";
import { useEffect, useState, useContext } from "react";

const Contato = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])

  return (
    <>
      <Title text="Контакты" />
      <div className={style.border}>
        <h3 style={{ textAlign: 'center' }}>Задать любые вопросы можно тут:</h3>

        {/* <div className={style.container_form}> */}



        <br />

        {/* <br/> */}


        <p className={style.p}><a style={{ color: 'black' }} href="mailto: kotopes.sale@gmail.com"><b>kotopes.sale@gmail.com</b></a></p>
        <br />
        <p className={style.p}>Адреса наших магазинов:</p>
        <p className={style.p}> Калининградская обл., г.Калининград, Советский Проспект, 204</p>
        <p className={style.p}><a href="tel:+74012387002">
          {/* <br></br> */}
          <b style={{ color: 'black' }}>+7 (4012) 38-70-02{"\n"}</b>
        </a></p>
        <p className={style.p}> Калининградская обл., г.Калининград, Олега Кошевого, 15</p>
        <p className={style.p}><a href="tel:+79097782815">
          {/* <br></br> */}
          <b style={{ color: 'black' }}>+7 (909) 778-28-15{"\n"}</b>
        </a></p>
        <p className={style.p}>Адрес производства:<br />
          236039, Калининградская обл., г.Калининград, ул. Причальная 5-я, д.1, ЛИТЕР Р, эт.1
        </p>
        {/* <br/> */}
        <p className={style.p}>
          ИП Бессонов Р.А.<br />
          ОГРНИП 323390000011683, ИНН 390600977795

        </p>
      </div>
      {/* </div> */}
      {/* <h1 style={{ textAlign: 'center', marginTop: '20px' }}>Написать нам прямо тут!</h1>
      <div className={style.container_form}>
        <form action='post' className={style.form}>
          <label for="name">Ваше Имя:</label>
          <input type="text" id="name" name="name" />
          <label for="email">Email:</label>
          <input type="email" id="email" name="email" />
          <p>Введите ваше сообщение</p>
          <textarea
            rows="8"
            id="experiencia"
            name="experiencia"
          ></textarea>
          <Button text='Отправить' />
        </form>
      </div> */}
    </>
  )
}

export default Contato