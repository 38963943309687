import React, { useContext, useEffect, useState } from "react";
import ProdutosDry from '../../Produtos/ProdutosDry'
import Title from '../../Title'
import style from './Loja.module.css'
import FarshData from '../../Produtos/FarshData';
import { QtdContext } from "../../../context/qtdContext";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Priuts = () => {
  const { priuts } = useContext(QtdContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  }, [])
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  return (
    <div className={style.container_geral_prod}>
      <Title text="Приюты" />
      <div style={{ width: '90%', margin: '5%', padding: '20px', borderRadius: '15px', background: 'white' }}>
        <p style={{ textAlign: 'center' }}>Мы добавили программу поддержки приютов с животными.
        </p>
        <p style={{ textAlign: 'center' }}>Вы можете пожертвовать товар доступному приюту с большой скидкой. Мы будем собирать корзину пожертвований и раз в месяц отвозить ее в приют.
        </p>
        <p style={{ textAlign: 'center' }}>Если вы владелец приюта, вы можете обратиться к нам, и мы БЕСПЛАТНО добавим вас к нам в программу.
        </p>
      </div>
      {priuts.map(priut => {
        const descriptions = priut.descriptions
        return (<Accordion expanded={expanded === `panel_${priut.id}`} onChange={handleChange(`panel_${priut.id}`)}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography className={style.ulmobitem}>{priut.name}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundImage: "url(https://api.kotopes39.ru/static/newback.png)", backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
            <h1 style={{ textAlign: 'center' }}>{priut.name}</h1>
            {descriptions.map(descriptionJSON => {
              const description = JSON.parse(descriptionJSON)
              return (<>
                <h2 style={{ textAlign: 'center' }}>{description.titel}</h2>

                <div style={{ width: '100%', height: 'auto', padding: '20px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'start', justifyContent: 'space-evenly' }}>
                  <div className={style.imgdesc} style={{ margin: '20px', padding: '20px', background: 'white', borderRadius: '15px' }}>
                    <img style={{
                      borderRadius: '15px',
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover'
                    }} src={description.photo} >

                    </img>
                  </div>
                  <div className={style.textdesc} style={{ margin: '20px', borderRadius: '15px', padding: '20px', textAlign: 'left', background: 'white' }}>
                    {description.about.map(aboutText => <p>{aboutText}</p>)}
                  </div>
                  <div className={style.textdesc} style={{ margin: '20px', borderRadius: '15px', width: '100%', padding: '20px', textAlign: 'left', background: 'white' }}>
                    <p >Вы можете связаться с нами по телефону <a style={{ color: 'black' }} href={`tel:${priut.tel}`}>{priut.tel}</a></p>
                    {priut.site && (
                      <p style={{width:'100%' }}> Наш сайт: <a style={{ color: 'black'}} href={priut.site}>Посетить</a></p>

                    )}
                  </div>
                </div>
              </>
              )
            })}
          </AccordionDetails>
        </Accordion>)
      })}
    </div>
  )
}

export default Priuts