import React, { useContext } from "react";
import { QtdContext } from "../../../context/qtdContext";
import style from "./ButtonsModal.module.css";

const ButtonsModalVes = ({ id, cartProdutoId, max }) => {
  const produtos = JSON.parse(localStorage.getItem("petNew"));
  const { serverProd, setServerProd, setQuantidadeModalBtn, setProdutosSelecionados } = useContext(QtdContext);
  const produtoQtd = serverProd.find((produto) => produto.id === id);
  const produtoCartQtd = produtos.find((produto) => produto.id === cartProdutoId);

  const getQuantidade = produtoCartQtd ? produtoCartQtd.quantidade : produtoQtd.quantidade;

  const addItemCart = () => {
    if (produtoCartQtd && produtoCartQtd.quantidade + 0.1 <= max) {
      const newQuantity = parseFloat((produtoCartQtd.quantidade + 0.1).toFixed(1));
      if (newQuantity === 0.2 || newQuantity === 0.1) return; // Prevents 0.1 or 0.2
      setQuantidadeModalBtn(produtoCartQtd.quantidade = newQuantity);
      const newPosts = produtos.map((post) => (
        post.id === id ? { ...post, quantidade: newQuantity } : post
      ));
      setProdutosSelecionados(newPosts);
      localStorage.setItem("petNew", JSON.stringify(newPosts));
      localStorage.setItem("quantidadeModalBtn", JSON.stringify(newQuantity));
    } else if (produtoQtd && produtoQtd.quantidade + 0.1 <= max) {
      const newQuantity = parseFloat((produtoQtd.quantidade + 0.1).toFixed(1));
      if (newQuantity === 0.2 || newQuantity === 0.1) return; // Prevents 0.1 or 0.2
      setQuantidadeModalBtn(produtoQtd.quantidade = newQuantity);
      const newPosts = serverProd.map((post) => (
        post.id === id ? { ...post, quantidade: newQuantity } : post
      ));
      setServerProd(newPosts);
    }
  };
  

  const removeItemCart = () => {
    if (produtoCartQtd && produtoCartQtd.quantidade > 0) {
      const newQuantity = parseFloat((produtoCartQtd.quantidade - 0.1).toFixed(1));
      if (newQuantity === 0.2 || newQuantity === 0.1) return; // Prevents 0.1 or 0.2
      setQuantidadeModalBtn(produtoCartQtd.quantidade = newQuantity);
      const newPosts = produtos.map((post) => (
        post.id === id ? { ...post, quantidade: newQuantity } : post
      ));
      setProdutosSelecionados(newPosts);
      localStorage.setItem("petNew", JSON.stringify(newPosts));
      localStorage.setItem("quantidadeModalBtn", JSON.stringify(newQuantity));
    } else if (produtoQtd && produtoQtd.quantidade > 0.3) {
      const newQuantity = parseFloat((produtoQtd.quantidade - 0.1).toFixed(1));
      if (newQuantity === 0.2 || newQuantity === 0.1) return; // Prevents 0.1 or 0.2
      setQuantidadeModalBtn(produtoQtd.quantidade = newQuantity);
      const newPosts = serverProd.map((post) => (
        post.id === id ? { ...post, quantidade: newQuantity } : post
      ));
      setServerProd(newPosts);
    }
  };

  return (
    <div className={style.select_qtd}>
      <button onClick={removeItemCart}>-</button>
      <p className={style.qtd}>{'  '}{getQuantidade}{'  '}</p>
      <button onClick={addItemCart}>+</button>
    </div>
  );
};

export default ButtonsModalVes;
