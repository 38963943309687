import React, { useContext } from 'react';
import { QtdContext } from "../../../context/qtdContext";
import style from "./ButtonsModal.module.css";

const ButtonsModalDonatw = ({ id, priut_id, max }) => {
  const {
    shops,
    setShops,
    productDots,
    serverProd,
    opt,
    setServerProd,
    setQuantidadeModalBtnDonations,
    setProdutosSelecionadosDonations,
  } = useContext(QtdContext);

  const produtos = JSON.parse(localStorage.getItem('produtosSelecionadosDonations')) || [];
  const produtoQtd = serverProd.find((produto) => produto.id === id);
  const produtoCartQtd = produtos.find((produto) => produto.id === id );

  const donations = produtoCartQtd
    ? produtoCartQtd.donations?.find((pr) => pr.id === priut_id) || { id: priut_id, qty: 0 }
    : produtoQtd?.donations?.find((pr) => pr.id === priut_id) || { id: priut_id, qty: 0 };

  const getQuantidade = donations.qty || 0;
  const getQuantidadeNormal = produtoCartQtd?.quantidade || produtoQtd?.quantidade || 0;

  const updateLocalStorage = (newData) => {
    localStorage.setItem('produtosSelecionadosDonations', JSON.stringify(newData));
    localStorage.setItem('localStorageInitTime', +new Date());
  };

  const addItemCart = () => {
    if (getQuantidadeNormal + getQuantidade + 1 > max) return;

    const newProdutos = produtos.map((post) => {
      if (post.id === id) {
        const priutnew = post.donations?.find(pr => pr.id == priut_id)
        if (priutnew) {
          const updatedDonations = post.donations?.map((pr) =>
            pr.id === priut_id ? { ...pr, qty: pr.qty + 1 } : pr
          ) || [{ ...donations, qty: 1 }];
          return { ...post, donations: updatedDonations };
        } else return { ...post, donations: [...post.donations, { id: priut_id, qty: 1 }] };

      }
      return post;
    });

    if (!produtoCartQtd) {
      newProdutos.push({ ...produtoQtd, donations: [{ id: priut_id, qty: 1 }] });
    }

    setProdutosSelecionadosDonations(newProdutos);
    updateLocalStorage(newProdutos);
    localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(newProdutos));

    setQuantidadeModalBtnDonations(getQuantidade );
  };

  const removeItemCart = () => {
    if (getQuantidade <= 0) return;

    const newProdutos = produtos.map((post) => {
      if (post.id === id) {
        const updatedDonations = post.donations
          ?.map((pr) =>
            pr.id === priut_id && pr.qty > 1 ? { ...pr, qty: pr.qty - 1 } : pr
          )
          .filter((pr) => pr.qty > 0);
        return { ...post, donations: updatedDonations };
      }
      return post;
    });

    setProdutosSelecionadosDonations(newProdutos);
    updateLocalStorage(newProdutos);
    localStorage.setItem("produtosSelecionadosDonations", JSON.stringify(newProdutos));

    setQuantidadeModalBtnDonations(getQuantidade - 1);
  };


  return (
    <div className={style.select_qtd}>
      <button onClick={removeItemCart}>-</button>
      <p className={style.qtd}>{getQuantidade}</p>
      <button onClick={addItemCart}>+</button>
    </div>
  );
};

export default ButtonsModalDonatw;
